import EngineerDetails from "./EngineerDetails";
import EscadasComponent from "./Escadas/EscadasComponent";
import PiscinasComponent from "./Piscinas/PiscinasComponent";

export function TodosComponent() {
  return (
    <div>
      <EngineerDetails />;
    </div>
  );
}
